@import "./scss/tailwindcss";
@import "../scss/components/variables";
@import "hamburgers/_sass/hamburgers/hamburgers";

// Uncomment to use tailwinds in theme styles (don't forget to comment out theme.scss in base.html as well)
// @import '../scss/theme';

// Bundle and App specific styles go here

.hamburger {
    line-height: 0;
}
