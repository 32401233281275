$spacingSingle: 20px;
$global-radius: 4px;


// =============================================================================
// COLORS
// =============================================================================


// Basics
// -----------------------------------------------------------------------------

$color-black:                       #000;
$color-white:                       #fff;
$color-lightgray:                   #f6f6f6;
$color-gray:                        #ebebeb;

// Brand colors. Feel free to add more
$color-primary:                     #2f2f2b;
$color-secondary:                   #cb0039;
$color-tertiary:                    #ebebeb;
$color-highlight:                   #edc70b;
$color-highlight-lighter:           #f4db63;
$color-hover:                       #cb0039;

$color-gray-light:                  #f6f6f6;
$color-gray-medium:                 #ebebeb;
$color-gray-dark:                   #64616d;
$color-gray-dark-alt:               #757575;

$color-background:                  #fff;
$color-border:                      #ebebeb;

$color-error:                       #cb0039;
$color-success:                     #008a06;


$border-width-default: 1px;
$borderBase: $border-width-default solid $color-border;


// Text
// -----------------------------------------------------------------------------
$color-textPrimary:                 $color-primary;

$color-textSecondary:               $color-gray-dark;
$color-textSecondary-hover:         $color-primary;

$color-textLink:                    $color-primary;
$color-textLink-hover:              $color-secondary;

$text-block-limit:                  830px;

// =============================================================================
// TYPE (Settings)
// =============================================================================

// Font sizes
// -----------------------------------------------------------------------------

$fontSize-base:                 14px; // 2
$fontSize-base--mobile:         14px;

$letterSpacing-base:            0;

$lineHeight-base:               calc(17/14); // 3

// We use these to define default font stacks
$fallback-stack-sans-serif:        Helvetica, Arial, sans-serif;
$fallback-stack-serif:             Georgia, Times, serif;

// We use these to control various global styles
$body-bg:                       #fff;
$body-font-color:               $color-textPrimary;
$body-font-family:              "Lato", $fallback-stack-sans-serif;
$body-font-weight:              400;

// We use these to control header font styles
$header-font-family:            "Racing Sans One", $fallback-stack-sans-serif;
$header-font-color:             $color-textPrimary;
$header-font-weight:            400;


// =============================================================================
// FORMS (Settings)
// =============================================================================

// We use this to set the base for lots of form spacing and positioning styles
$form-spacing:                      $spacingSingle;

// We use these to style the labels in different ways

$input-font-family:                 $body-font-family;
$input-font-color:                  $body-font-color;
$input-font-placeholder-color:      $color-gray-dark-alt;
$input-font-size:                   $fontSize-base;
$input-bg-color:                    $color-background;
$input-focus-bg-color:              $color-background;
$input-border-color:                $color-primary;
$input-focus-border-color:          $color-hover;
$input-border-style:                solid;
$input-border-width:                $border-width-default;
$input-border-radius:               $global-radius;
$input-letter-spacing:              $letterSpacing-base;

$form-label-small-color:            $color-textSecondary;

$input-height:                      34px;


// Stencil Variables
// -----------------------------------------------------------------------------

$select-borderRadius:               $global-radius;
$select-hover-borderColor:          null;
$select-arrow-color:                rgba($color-primary, 0.999);
$select-backgroundImage:            url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='10' viewBox='0 0 18 10'%3E%3Cpath d='M9,10c-.26,0-.51-.1-.71-.29L.29,1.71C-.1,1.32-.1,.68,.29,.29S1.32-.1,1.71,.29l7.29,7.29L16.29,.29c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41L9.71,9.71c-.2,.2-.45,.29-.71,.29Z' fill='#{$select-arrow-color}' /%3E%3C/svg%3E");
$select-backgroundPosition:         right 8px top 50%;

$select-arrow-color-category:       rgba($color-primary, 0.999);
$select-backgroundImage-category:   url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='10' viewBox='0 0 18 10'%3E%3Cpath d='M9,10c-.26,0-.51-.1-.71-.29L.29,1.71C-.1,1.32-.1,.68,.29,.29S1.32-.1,1.71,.29l7.29,7.29L16.29,.29c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41L9.71,9.71c-.2,.2-.45,.29-.71,.29Z' fill='#{$select-arrow-color-category}' /%3E%3C/svg%3E");


$select-arrow-color-alternate:      rgba($color-white, 0.999);
$select-backgroundImage-alternate:  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='10' viewBox='0 0 18 10'%3E%3Cpath d='M9,10c-.26,0-.51-.1-.71-.29L.29,1.71C-.1,1.32-.1,.68,.29,.29S1.32-.1,1.71,.29l7.29,7.29L16.29,.29c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41L9.71,9.71c-.2,.2-.45,.29-.71,.29Z' fill='#{$select-arrow-color-alternate}' /%3E%3C/svg%3E");

$checkmark-color:                   rgba($color-white, 0.999);
$checkmark-background-color:        rgba($color-black, 0.999);
$checkmark-backgroundImage:         url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' width='9' height='7' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 9 7' xml:space='preserve' fill='#{$checkmark-color}'%3E%3Cpath d='M8.8,0.2c0.3,0.3,0.3,0.8,0,1.1L3.7,6.8c-0.3,0.3-0.8,0.3-1.1,0L0.2,4.2c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0l1.9,2l4.5-4.8C8-0.1,8.5-0.1,8.8,0.2'/%3E%3C/svg%3E");

$radio-color:                       rgba($color-primary, 0.999);
$radio-backgroundImage:             url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' width='20' height='20' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 20 20' xml:space='preserve'%3E%3Ccircle cx='10' cy='10' r='7' fill='#{$radio-color}' /%3E%3C/svg%3E");

$form-label-small-marginTop:        3px;

$input-small-padding:               4px 7px;
$input-small-height:                35px;
$input-small-fontSize:              14px;


// Stencil Variables
// -----------------------------------------------------------------------------

$form-success-icon:                         url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z' fill='#{rgba($color-success, 0.999)}' /%3E%3C/svg%3E");
$form-success-icon-height:                  24px;
$form-success-icon-top:                     37px;
$form-success-icon-right:                   9px;
$form-success-icon-check-right:             30px;
$form-success-icon-width:                   24px;
$form-success-input-paddingRight:           40px;

$form-error-icon:                           url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' fill='#{rgba($color-error, 0.999)}' /%3E%3C/svg%3E");
$form-error-icon-height:                    14px;
$form-error-icon-left:                      -2px;
$form-error-icon-marginRight:               1px;
$form-error-icon-top:                       3px;
$form-error-icon-width:                     14px;

$form-inlineMessage-backgroundSize:         100%;

$form-checkRadio-label-last-marginBottom:   -4px;
$form-checkRadio-labelBefore-top:           3px;
$form-checkRadio-labelAfter-top:            4px;

$form-minMaxRow-column-gutter:              5px;



// =============================================================================
// MODAL (Settings)
// =============================================================================

// Foundation Variables
// -----------------------------------------------------------------------------

// We use these to control the style of the modal itself.
$reveal-modal-paddingHorizontal:   $spacingSingle;
$reveal-modal-paddingVertical:     $spacingSingle * 1.5;
$reveal-modal-padding:             $reveal-modal-paddingVertical $reveal-modal-paddingHorizontal;

// We use these to style the reveal close button
$reveal-close-height:              32px;

// =============================================================================
// CHECKS AND RADIOS
// =============================================================================
// sizing
$checkRadio-outerSize:                      20px;
$checkRadio-innerSize:                      $checkRadio-outerSize - 2;

// =============================================================================
// HEADER (Settings)
// =============================================================================

$header-bg:                                 #fff;
$header-height:                             75px;
$menu-height:                               60px;
$header-height-mobile:                      65px;
$header-toggle-width:                       $header-height;
$header-logo-marginVertical:                10px;
$header-logo-paddingVertical:               $header-logo-marginVertical;
$promo-height:                              45px;
$promo-height-mobile:                       25px;
$total-header-height-desktop:               $header-height + $menu-height;

// Off Canvas Tab Bar Variables
$tabbar-bg: #fff;
$tabbar-height: $header-height-mobile;
$tabbar-color: #000;
$tabbar-middle-padding: 0 10px;

// Off Canvas Divider Styles
$tabbar-left-section-border: none 0 transparent;
// $tabbar-right-section-border: $tabbar-left-section-border;


// Off Canvas Tab Bar Headers
$tabbar-header-color: #000;
$tabbar-header-weight: 700;
$tabbar-header-line-height: 1.6;
// $tabbar-header-margin: 0;

// Off Canvas Menu Variables
$off-canvas-width: 275px;
$off-canvas-height: auto;
$off-canvas-bg: #fff;
$off-canvas-bg-hover: $color-white;
$off-canvas-bg-active: $color-white;

// Off Canvas Menu List Variables
$off-canvas-label-color: $color-textPrimary;
$off-canvas-label-font-size: 18px;
$off-canvas-label-bg: $color-primary;
$off-canvas-label-border-top: 1px solid $color-gray-dark-alt;
$off-canvas-label-border-bottom: $off-canvas-label-border-top;
$off-canvas-link-line-height: 1;
$off-canvas-link-padding-vertical: 15px;
$off-canvas-link-padding-horizontal: 22px;
$off-canvas-link-padding: $off-canvas-link-padding-vertical $off-canvas-link-padding-horizontal;
$off-canvas-link-color: $color-textPrimary;
$off-canvas-link-color-hover: #fff;
$off-canvas-link-border-bottom: $off-canvas-label-border-top;
$off-canvas-back-bg: $color-textPrimary;
$off-canvas-back-border-top: none;
$off-canvas-back-border-bottom: $borderBase;
$off-canvas-back-hover-bg: $color-primary;
$off-canvas-back-hover-border-top: none;
$off-canvas-back-hover-border-bottom: $borderBase;

$off-canvas-label-padding: $off-canvas-link-padding;

// Off Canvas Menu Icon Variables
$tabbar-menu-icon-color: #000;
$tabbar-menu-icon-hover: lighten($tabbar-menu-icon-color, 70%);

$tabbar-menu-icon-text-indent: 35px;

$tabbar-hamburger-icon-width: 16px;
$off-canvas-overlay-background: rgba(#000, 0.3);
$off-canvas-overlay-background-hover: $off-canvas-overlay-background;


// =============================================================================
// NAV PAGES (Settings)
// =============================================================================
$navPage-subMenu-backgroundColor:        #fff;

$navPages-color: $color-primary;
$navPages-colorHover: $color-primary;

$navUser-color: $color-primary;
$navUser-colorHover: $color-primary;

$navDelay: 0;
$navLeaveDelay: 50ms;

// =============================================================================
// SOCIAL LINKS (Settings)
// =============================================================================
$socialLinks-alt-iconColor:             $color-textSecondary;


// Buttons
$button-height:                              60px;
$button-line-height:                         calc(14/18);
$buttonStyle-default-color:                  $color-primary;
$buttonStyle-default-colorHover:             $color-white;
$buttonStyle-default-backgroundColor:        $color-white;
$buttonStyle-default-backgroundColorHover:   $color-secondary;
$buttonStyle-default-borderColor:            $color-secondary;
$buttonStyle-default-borderColorHover:       $color-primary;

$buttonStyle-primary-color:                  $color-primary;
$buttonStyle-primary-colorHover:             $color-primary;
$buttonStyle-primary-backgroundColor:        $color-highlight;
$buttonStyle-primary-backgroundColorHover:   $color-highlight-lighter;
$buttonStyle-primary-borderColor:            $color-highlight;
$buttonStyle-primary-borderColorHover:       $color-highlight;

$buttonStyle-secondary-color:                  $color-white;
$buttonStyle-secondary-colorHover:             $color-white;
$buttonStyle-secondary-backgroundColor:        $color-primary;
$buttonStyle-secondary-backgroundColorHover:   $color-secondary;
$buttonStyle-secondary-borderColor:            $color-secondary;
$buttonStyle-secondary-borderColorHover:       $color-primary;


// Icons
$icon-color: $body-font-color;
$icon-color-hover: $color-secondary;

// Cart variables
// -----------------------------------------------------------------------------

$cart-item-spacing:                     $spacingSingle;

$cart-thumbnail-maxWidth:               222px;
$cart-thumbnail-height:                 210px;

$cart-thumbnail-paddingVertical:        0.5rem;

$cart-item-label-offset:                $cart-thumbnail-maxWidth + $cart-item-spacing;

// Maintenance Notice
// -------------------------------------
$maintenanceNotice-backgroundColor:     #999;
$maintenanceNotice-box-shadow:          0 0 5px #ccc;
$maintenanceNotice-color:               #333;
$maintenanceNotice-left:                16px;
$maintenanceNotice-padding:             16px 18px;
$maintenanceNotice-top:                 16px;
$maintenanceNotice-width:               24rem;
$maintenanceNotice-z-index:             98;

$focusTooltip-backgroundColor: $color-secondary;


// Focus States
$outline-width:  2px;
$outline-style:  solid;
$outline-color:  #0f7fff;
$outline-offset: 1px;

// =============================================================================
// Hamburger Menu
// =============================================================================

// $hamburger-padding-x: 15px;
// $hamburger-padding-y: 15px;
$hamburger-layer-width: 20px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 5px;
// $hamburger-layer-color: #000;
// $hamburger-layer-border-radius: 4px;
// $hamburger-hover-opacity: 0.7;
// $hamburger-active-layer-color: $hamburger-layer-color;
// $hamburger-active-hover-opacity: $hamburger-hover-opacity;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
// $hamburger-hover-use-filter: false;
// $hamburger-hover-filter: opacity(50%);
// $hamburger-active-hover-filter: $hamburger-hover-filter;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (
    // 3dx,
    // 3dx-r,
    // 3dy,
    // 3dy-r,
    // 3dxy,
    // 3dxy-r,
    // arrow,
    // arrow-r,
    // arrowalt,
    // arrowalt-r,
    // arrowturn,
    // arrowturn-r,
    // boring,
    // collapse,
    // collapse-r,
    elastic,
    // elastic-r,
    // emphatic,
    // emphatic-r,
    // minus,
    // slider,
    // slider-r,
    // spin,
    // spin-r,
    // spring,
    // spring-r,
    // stand,
    // stand-r,
    // squeeze,
    // vortex,
    // vortex-r
);
